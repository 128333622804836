import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import oval1 from "@images/oval.svg"
import oval2 from "@images/oval-2.svg"
import oval3 from "@images/oval-3.svg"

// Inline styling

const termsContent = {
  padding: "10rem 0 5rem 0",
  position: "relative",
  overflow: "hidden",
}

const heroHeader = {
  fontSize: 40,
  marginBottom: "2rem",
}

// end of styling

const Solution = ({location}) => {
  return (
    <>
      <Layout location={location}>
        <SEO title="Solution" description="" />

        <div className="innerpages-hero">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="hero-text-wrapper text-center">
                  <h2 style={heroHeader}>Solution</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="terms-content" style={termsContent}>
          <img className="oval-svg" src={oval1} alt="Oval svg" />
          <img className="oval-2" src={oval2} alt="Oval svg" />
          <img className="oval-3" src={oval3} alt="Oval svg" />
          <div className="container">
            <div className="row">
              <div className="col-10 m-auto">
                <p>Content here...</p>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default Solution
